import { For, Show, batch, createEffect, splitProps } from "solid-js";
import {
  AMaterialLesson,
  AMaterialLessonBlock,
  AMaterialLessonGroup,
  AMaterialLessonItem,
  EditMaterialContentLessonSidePanelViewProps,
} from "../../../../models";
import { Atom, Cmp, state } from ":mods";
import { CSSStyler } from "./css-styler";
import { Accordion } from "./accordion";
import { AccordionPage } from "./accordion-page";
import { AccordionItem } from "./accordion-item";
import { materialContentLessonTemplateDetails } from "../../../../mocks/material-lesson";
import { ExportImport } from "./export-import";

export function SidePanel(props: AMaterialLesson) {
  // const [local, others] = splitProps(props, ["$", "content"]);
  const $material_lesson = state.create(props ?? ({ name: "New Page" } as AMaterialLesson));
  const $selected = state.create({
    block: undefined as { value: AMaterialLessonBlock; index: () => number },
    group: undefined as { value: AMaterialLessonGroup; index: () => number },
    item: undefined as { value: AMaterialLessonItem; index: () => number },
  });

  // createEffect(() => {
  //   console.log("update selected page builder", $page_builder.value);
  //   console.log("update selected ", $selected.value.block);
  // });

  function updateBlock(items: AMaterialLessonGroup[]) {
    const block = $selected.value.block;
    block.value.groups = items;
    batch(() => {
      $material_lesson.set((s) => {
        s.blocks[block.index()] = { ...block.value };
        return { ...s };
      });
      $selected.set((s) => ({ ...s, block: block }));
    });
  }
  function updateGroup(items: AMaterialLessonItem[]) {
    const block = $selected.value.block;
    const group = $selected.value.group;
    group.value.items = items;
    // block.value.groups[group.index()] = { ...group.value };
    batch(() => {
      $material_lesson.set((s) => {
        // s.blocks[block.index()].groups[group.index()] = { ...group.value };
        s.blocks[block.index()] = { ...block.value };
        return { ...s };
      });
      $selected.set((s) => ({ ...s, group: group }));
    });
  }
  function updateItem(items: AMaterialLessonItem) {
    const block = $selected.value.block;
    const group = $selected.value.group;
    const item = $selected.value.item;
    item.value = items;
    group.value.items[item.index()] = { ...item.value };
    block.value.groups[group.index()] = { ...group.value };
    batch(() => {
      $material_lesson.set((s) => {
        // s.blocks[block.index()].groups[group.index()].items[item.index()] = { ...item.value };
        s.blocks[block.index()] = { ...block.value };
        return { ...s };
      });
      $selected.set((s) => ({ ...s, item: item }));
    });
  }
  return {
    $material_lesson,
    View: (props: EditMaterialContentLessonSidePanelViewProps) => {
      const [local, others] = splitProps(props, ["$", "onUpdate"]);
      return (
        <div {...others}>
          <div class="flex flex-col px-2 py-3">
            <button
              class="form-btn"
              onclick={() => {
                local.onUpdate($material_lesson.unwrap);
              }}
            >
              Save
            </button>
            <ExportImport
              onImport={(d) => {
                $material_lesson.set(d);
              }}
              onExport={() => $material_lesson.unwrap}
            />
          </div>
          {/* page */}
          <AccordionPage
            title="page"
            object={$material_lesson.value}
            defaultStyle={materialContentLessonTemplateDetails.data.pages[0].template.style}
            arrayKey="blocks"
            newNamePrefix="Block"
            newOptions={materialContentLessonTemplateDetails.data.blocks}
            onNewOption={(option) => {
              // console.log("material_lesson :: ", $material_lesson.value);
              $material_lesson.set((s) => {
                return { ...s, blocks: [...(s.blocks ?? []), { ...option.template, name: option.name }] };
              });
            }}
            onUpdate={(items, index) => {
              const block = $selected.value.block;
              if (index !== undefined && block && block.index() === index) {
                $selected.set((s) => ({ ...s, block: undefined, group: undefined, item: undefined }));
              }
              $material_lesson.set((s) => ({ ...s, blocks: items }));
            }}
            onSelected={(key, value, index) => $selected.set((s) => ({ ...s, block: { value, index } }))}
            onStyle={(style) => $material_lesson.set((s) => ({ ...s, style }))}
          />
          {/* block */}
          <AccordionPage
            title={!$selected.value.block ? "Please Choose A Block" : `${$selected.value.block.value.name} settings `}
            object={$selected.value.block?.value}
            arrayKey="groups"
            newNamePrefix="Group"
            newOptions={materialContentLessonTemplateDetails.data.groups}
            onNewOption={(option) => {
              const groups = [...$selected.value.block.value.groups];
              groups.push({ ...option.template, name: option.name });
              updateBlock(groups);
            }}
            onUpdate={(items, index) => {
              const group = $selected.value.group;
              if (index !== undefined && group && group.index() === index) {
                $selected.set((s) => ({ ...s, group: undefined, item: undefined }));
              }
              updateBlock(items);
            }}
            onSelected={(key, value, index) => $selected.set((s) => ({ ...s, group: { value, index } }))}
            onStyle={(style) => {
              const block_index = $selected.value.block.index();
              const new_block = $selected.value.block.value;
              new_block.style = style;
              batch(() => {
                $material_lesson.set((s) => {
                  s.blocks[block_index] = { ...new_block };
                  return { ...s, blocks: [...s.blocks] };
                });
                $selected.set((s) => ({ ...s, block: { ...s.block, value: new_block } }));
              });
            }}
          />
          {/* group */}
          <AccordionPage
            title={!$selected.value.group ? "Please Choose A Group" : `${$selected.value.group.value.name} settings `}
            object={$selected.value.group?.value}
            arrayKey="items"
            newNamePrefix="Item"
            newObjectTemplate={{ type: "text", value: "" }}
            newOptions={materialContentLessonTemplateDetails.data.items}
            onNewOption={(option) => {
              const items = [...$selected.value.group.value.items];
              items.push({ ...option.template, name: option.name });
              updateGroup(items);
            }}
            onUpdate={(items, index) => {
              const item = $selected.value.item;
              if (index !== undefined && item && item.index() === index) {
                $selected.set((s) => ({ ...s, item: undefined }));
              }
              updateGroup(items);
            }}
            onSelected={(key, value, index) => $selected.set((s) => ({ ...s, item: { value, index } }))}
            onStyle={(style) => {
              const block_index = $selected.value.block.index();
              const group_index = $selected.value.group.index();
              const new_block = $selected.value.block.value;
              const new_group = $selected.value.group.value;
              new_group.style = style;
              new_block.groups[group_index] = { ...new_group };
              batch(() => {
                $material_lesson.set((s) => {
                  s.blocks[block_index] = { ...new_block };
                  return { ...s, blocks: [...s.blocks] };
                });
                $selected.set((s) => ({ ...s, group: { ...s.group, value: new_group } }));
              });
            }}
          />
          {/* item */}
          <AccordionItem
            title={!$selected.value.item ? "Please Choose A Item" : `${$selected.value.item.value.name} settings `}
            object={$selected.value.item?.value}
            onUpdate={(items) => updateItem(items)}
            onStyle={(style) => {
              const block_index = $selected.value.block.index();
              const group_index = $selected.value.group.index();
              const item_index = $selected.value.item.index();
              const new_block = $selected.value.block.value;
              const new_group = $selected.value.group.value;
              const new_item = $selected.value.item.value;
              new_item.style = style;
              new_group.items[item_index] = { ...new_item };
              new_block.groups[group_index] = { ...new_group };
              batch(() => {
                $material_lesson.set((s) => {
                  s.blocks[block_index] = { ...new_block };
                  return { ...s, blocks: [...s.blocks] };
                });
                $selected.set((s) => ({ ...s, item: { ...s.item, value: new_item } }));
              });
            }}
          />
        </div>
      );
    },
  };
}
